<template>
  <div class="home pb0">
    <CommonHeader title="买家余额" />
    <van-list
      v-model="isLoading"
      :finished="!hasMore"
      :immediate-check="false"
      finished-text="我也是有底线的"
      @load="loadMoreData"
    >
      <div class="my-wallet-view">
        <div class="my-wallet">
          <div class="can-use-wallet flex">
            <div class="flex-one">
              <div class="t">可用余额({{ merchantInfo.fee_type_text }})</div>
              <div class="b">{{ formatPriceNumber(balance, 2) }}</div>
            </div>
            <div class="flex-one">
              <div class="t">冻结余额({{ merchantInfo.fee_type_text }})</div>
              <div class="b">{{ formatPriceNumber(frozen_balance, 2) }}</div>
            </div>
          </div>
          <div class="btns item-flex-center">
            <div
              class="btn"
              :style="rightBtnStyle"
              @click="goPageAction('/users/recharge')"
            >充值</div>
            <!-- <div
              class="btn"
              :style="leftBtnStyle"
              @click="goPageAction('/users/withdrawal')"
            >退款</div> -->
          </div>
        </div>
      </div>
      <div class="record">
        <van-sticky :offset-top="88">
          <div class="record-title flex">
            <div class="flex-one l flex align-items-c">
              <!-- <div
                class="item"
                :style="{ color: (type == 1 ? colorData.specialColor : ''), fontWeight: (type == 1 ? 500 : '') }"
                @click="changeTypeAction(1)"
              >充值 <div
                  class="bg"
                  :style="{ background: (type == 1 ? colorData.specialColor : '') }"
                ></div>
              </div>
              <div
                class="item"
                :style="{ color: (type == 2 ? colorData.specialColor : ''), fontWeight: (type == 2 ? 500 : '') }"
                @click="changeTypeAction(2)"
              >冻结 <div
                  class="bg"
                  :style="{ background: (type == 2 ? colorData.specialColor : '') }"
                ></div>
              </div>
              <div
                class="item"
                :style="{ color: (type == 3 ? colorData.specialColor : ''), fontWeight: (type == 3 ? 500 : '') }"
                @click="changeTypeAction(3)"
              >消费 <div
                  class="bg"
                  :style="{ background: (type == 3 ? colorData.specialColor : '') }"
                ></div>
              </div>
              <div
                class="item"
                :style="{ color: (type == 4 ? colorData.specialColor : ''), fontWeight: (type == 4 ? 500 : '') }"
                @click="changeTypeAction(4)"
              >退款 <div
                  class="bg"
                  :style="{ background: (type == 4 ? colorData.specialColor : '') }"
                ></div>
              </div> -->
              <div
                class="item"
                :style="{ color: (type == 0 ? colorData.specialColor : ''), fontWeight: (type == 0 ? 500 : '') }"
                @click="changeTypeAction(0)"
              >全部 <div
                  class="bg"
                  :style="{ background: (type == 0 ? colorData.specialColor : '') }"
                ></div>
              </div>
              <div
                class="item"
                :style="{ color: (type == 1 ? colorData.specialColor : ''), fontWeight: (type == 1 ? 500 : '') }"
                @click="changeTypeAction(1)"
              >消费 <div
                  class="bg"
                  :style="{ background: (type == 1 ? colorData.specialColor : '') }"
                ></div>
              </div>
              <div
                class="item"
                :style="{ color: (type == 2 ? colorData.specialColor : ''), fontWeight: (type == 2 ? 500 : '') }"
                @click="changeTypeAction(2)"
              >充值 <div
                  class="bg"
                  :style="{ background: (type == 2 ? colorData.specialColor : '') }"
                ></div>
              </div>
            </div>
            <!-- <div
              class="r flex align-items-c"
              @click="editTimeType"
            >
              <div>按时间</div>
              <img
                v-if="timeType == 1"
                src="https://artimg2.artart.cn/image/20220126/b0630c3334671bfba14b349d0c577978.png"
              >
              <img
                v-else-if="timeType == 2"
                src="https://artimg2.artart.cn/image/20220126/026f53456fb0a9db9f72bb97540dca7c.png"
              >
            </div> -->
          </div>
        </van-sticky>
        <div class="record-list">
          <template
            v-for="(item, index) in list"
            :key="`list${index}`"
          >
              <div
                class="item flex"
              >
                <div class="l flex-one">
                  <div class="lt flex align-items-c">
                    <div class="ft14 fw500">{{ item.title }}</div>
                    <div class="ml8 color-gray">{{ item.create_time }}</div>
                  </div>
                </div>
                <div class="r">
                  <div class="hei" v-if="item.pm == 1">+{{ formatPriceNumber(item.number,2) }}</div>
                  <div class="price" v-else>-{{ formatPriceNumber(item.number, 2) }}</div>
                </div>
              </div>
            <!-- <template v-if="type == 1">
              <div
                class="item flex"
                @click="clickOrderAction(item)"
              >
                <div class="l flex-one">
                  <div class="lt flex align-items-c">
                    <div class="ft14 fw500">{{ item.pay_type_text }}</div>
                    <div class="ml8 color-gray">{{ item.create_time }}</div>
                  </div>
                  <div
                    v-if="item.pay_type == 'bank_transfer'"
                    class="lb"
                    :class="{ 'has-img': item.pay_type == 'bank_transfer' }"
                  >银行转账凭证</div>
                  <div
                    v-else
                    class="lb"
                  >订单号 {{ item.out_trade_no }}</div>
                </div>
                <div class="r">
                  <div :class="{ 'hei': item.pay_type == 'bank_transfer' }">+{{ formatPriceNumber(item.total_fee) }}</div>
                  <div
                    class="rb"
                    v-if="item.pay_status == 2"
                  >审核中</div>
                </div>
              </div>
            </template>
            <template v-else-if="type == 2">
              <div
                class="item flex"
                @click="clickFrozenOrderAction(item)"
              >
                <div class="l flex-one">
                  <div class="lt flex align-items-c">
                    <div class="ft14 fw500">{{ item.frozen_type_text }}</div>
                    <div class="ml8 color-gray">{{ item.create_time }}</div>
                  </div>
                  <div class="lb flex align-items-c">
                    <div class="line1">{{ item.frozen_type == 'frozen_price' ? item.related_info.store_name : item.related_info.album_name }}</div>
                    <img
                      src="https://artimg2.artart.cn/image/20220307/4ba169ad6a697a06e2126986986566c9.png"
                      alt=""
                    >
                  </div>
                </div>
                <div class="r">
                  <div :class="{ 'hong': item.frozen_status != 2, 'hei': item.frozen_status == 2 }">{{ formatPriceNumber(item.frozen_price, 2) }}</div>
                  <div
                    class="rb"
                    :class="{ 'frozen': item.frozen_status != 2 }"
                  ><template v-if="item.frozen_status == 1">冻结中</template><template v-else-if="item.frozen_status == 2">已解冻</template><template v-else-if="item.frozen_status == 3">违约扣除</template></div>
                </div>
              </div>
            </template>
            <template v-else-if="type == 3">
              <div class="item ">
                <div
                  class="flex"
                  @click="clickHistoryOrderAction(item)"
                >
                  <div class="l flex-one">
                    <div class="lt flex align-items-c">
                      <div class="ft14 fw500">{{ item.category_text }}</div>
                      <div class="ml8 color-gray">{{ item.create_time }}</div>
                    </div>
                    <div
                      v-if="item.related_trans_info.orderList && item.related_trans_info.orderList.length == 1"
                      class="lb"
                    >订单号 {{ item.related_trans_info.orderList[0].order_sn }}</div>
                    <div
                      v-if="item.related_trans_info.orderList && item.related_trans_info.orderList.length > 1"
                      class="lb"
                    >共 {{ item.related_trans_info.orderList.length }} 个订单</div>
                  </div>
                  <div class="r">
                    <div class="hei">-{{ formatPriceNumber(item.amount, 2) }}</div>
                    <div
                      v-if="item.related_trans_info.orderList && item.related_trans_info.orderList.length > 1"
                      class="rb detail"
                    >查看订单</div>
                  </div>
                </div>
                <div
                  class="show-detail-view"
                  v-if="item.related_trans_info.orderList && item.related_trans_info.orderList.length > 1 && item.is_show"
                >
                  <div class="show-detail">
                    <div
                      class="detail-it"
                      v-for="(m, i) in item.related_trans_info.orderList"
                      :key="`list${index}${i}`"
                      @click="goOrderAction(m.order_id)"
                    >
                      <div class="name">订单 {{ m.order_sn }}</div>
                      <div class="price">-{{ formatPriceNumber(m.pay_price, 2) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="type == 4">
              <div class="item">
                <div
                  class="flex"
                  @click="clickExOrderAction(item)"
                >
                  <div class="l flex-one">
                    <div class="lt flex align-items-c">
                      <div class="ft14 fw500">{{ item.extract_title }}</div>
                    </div>
                    <div class="lb">{{ item.create_time }}</div>
                  </div>
                  <div class="r">
                    <div class="hei">-{{ formatPriceNumber(item.extract_price, 2) }}</div>
                    <div class="flex align-items-c justify-e">
                      <div
                        v-if="item.status == -1"
                        class="rb err"
                      >拒绝退款</div>
                      <div
                        v-else-if="item.status == 0"
                        class="rb ing"
                      >处理中</div>
                      <div
                        v-else-if="item.status == 1"
                        class="rb finish"
                      >退款成功</div>
                      <div
                        v-if="item.refund.length > 0"
                        class="rb detail"
                      >退款详情</div>
                    </div>
                  </div>
                </div>
                <div
                  class="show-detail-view"
                  v-if="item.refund.length > 0 && item.is_show"
                >
                  <div class="show-detail">
                    <div
                      class="detail-it"
                      v-for="(m, i) in item.refund"
                      :key="`list${index}${i}`"
                    >
                      <div class="name">{{ m.pay_type_text }} <span>{{ m.create_time }}</span></div>
                      <div class="price">{{ formatPriceNumber(m.amount, 2) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template> -->
          </template>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref, onBeforeMount } from "vue";
import { Sticky, Toast, ImagePreview } from "vant";
import { formatPriceNumber, isWeiXin } from "@/utils/util";
import {
  accountInfo,
  rechargeList,
  frozenList,
  extractList,
  historyList,
  getCommissionInfo
} from "@/api/user";

export default {
  components: {
    [Sticky.name]: Sticky,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    // 筛选状态 1 充值   2 冻结   3 消费   4 退款
    const type = ref(0);
    // 按时间  1 倒序  2正序
    const timeType = ref(1);

    const balance = ref(""); // 余额
    const frozen_balance = ref(""); // 冻结余额

    const isLoading = ref(false); // 列表加载控制器
    const hasMore = ref(true); // 是否有更多数据
    const queryParams = ref({
      page: 1,
      limit: 10,
    });
    const list = ref([]); // 列表

    // 获取button样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    onBeforeMount(() => {
      accountInfo()
        .then((res) => {
          balance.value = res.data.now_money;
          frozen_balance.value = res.data.frozen_balance;
        })
        .catch((message) => Toast(message));
      isLoading.value = true;
      load();
    });

    const load = () => {
      let action = getCommissionInfo;
      // switch (type.value) {
      //   case 1:
      //     action = rechargeList;
      //     break;
      //   case 2:
      //     action = frozenList;
      //     break;
      //   case 3:
      //     queryParams.value.type = "pay";
      //     action = historyList;
      //     break;
      //   case 4:
      //     action = extractList;
      //     break;
      // }
      queryParams.value.order = timeType.value == 2 ? "time_asc" : "time_desc";
      action(queryParams.value)
        .then((res) => {
          res.data.list.forEach((m) => {
            m.is_show = false;
          });
          if (queryParams.value.page == 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }

          if (list.value.length >= res.data.count) {
            hasMore.value = false;
          } else {
            hasMore.value = true;
          }
          isLoading.value = false;
        })
        .catch((message) => {
          Toast(message);
          isLoading.value = false;
        });
    };

    // 选择筛选类型
    const changeTypeAction = (t) => {
      type.value = t;
      list.value = [];
      hasMore.value = true;
      isLoading.value = true;
      queryParams.value.page = 1;
      queryParams.value.type = t;
      load();
    };

    // 时间  正序倒序筛选  修改
    const editTimeType = () => {
      timeType.value = timeType.value === 1 ? 2 : 1;
      list.value = [];
      hasMore.value = true;
      isLoading.value = true;
      queryParams.value.page = 1;
      load();
    };

    const loadMoreData = () => {
      // 查看更多
      if (!isLoading.value && hasMore.value) {
        isLoading.value = true;
        queryParams.value.page = queryParams.value.page + 1;
        load();
      }
    };

    const goPageAction = (link) => {
      // 跳转页面
      if (link == "/users/recharge") {
        const isWx = isWeiXin();
        if (isWx) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.redirectTo({
                url: "/pages/recharge/recharge?back=1",
              });
            } else {
              router.push(link);
            }
          });
        } else {
          router.push(link);
        }
        return false;
      }
      router.push(link);
    };

    const clickOrderAction = (m) => {
      // 点击记录事件
      if (m.pay_type == "bank_transfer" && m.pay_evidence) {
        ImagePreview([m.pay_evidence]);
      }
    };
    const clickExOrderAction = (m) => {
      // 点击记录事件
      if (m.refund.length > 0) {
        m.is_show = !m.is_show;
      }
    };
    const clickHistoryOrderAction = (m) => {
      // 点击消费记录事件  多个订单展开   单个订单直接跳转
      if (
        m.related_trans_info.orderList &&
        m.related_trans_info.orderList.length == 1
      ) {
        router.push(
          "/order/detail?id=" + m.related_trans_info.orderList[0].order_id
        );
      } else if (
        m.related_trans_info.orderList &&
        m.related_trans_info.orderList.length > 1
      ) {
        m.is_show = !m.is_show;
      }
    };

    const goOrderAction = (id) => {
      // 去订单详情
      router.push("/order/detail?id=" + id);
    };

    const clickFrozenOrderAction = (m) => {
      // 点击冻结列表 跳转
      let link = "";
      if (m.frozen_type == "album_bond") {
        link = "/pages/albumDetail/index?id=" + m.related_info.album_id;
      } else if (m.frozen_type == "frozen_price") {
        link = "/pages/auctionDetail/index?id=" + m.related_info.product_id;
      }

      const isWx = isWeiXin();
      if (isWx) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: link,
            });
          } else {
            router.push(link);
          }
        });
      } else {
        router.push(link);
      }
    };

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    return {
      type,
      timeType,
      rightBtnStyle,
      leftBtnStyle,
      colorData,
      formatPriceNumber,
      frozen_balance,
      balance,
      list,
      isLoading,
      hasMore,
      merchantInfo,

      editTimeType,
      changeTypeAction,
      loadMoreData,
      goPageAction,
      clickOrderAction,
      clickExOrderAction,
      clickHistoryOrderAction,
      goOrderAction,
      clickFrozenOrderAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f5f5f5;
}
.my-wallet-view {
  padding-top: 32px;
}
.my-wallet {
  margin: 0 32px 32px 32px;
  background: #fff;
  border-radius: 16px;
  padding-bottom: 64px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

  .can-use-wallet {
    padding-top: 48px;

    .t,
    .b {
      text-align: center;
    }

    .t {
      color: #666;
      font-size: 24px;
    }

    .b {
      font-size: 42px;
      font-family: NUM;
      margin-top: 8px;
      color: #000;
    }
  }

  .btns {
    margin-top: 48px;

    .btn {
      width: 250px;
      height: 72px;
      margin: 0 16px;
      text-align: center;
      line-height: 72px;
    }
  }
}

.record {
  background: #fff;
  .record-title {
    padding: 0 8px;
    background: #fff;
    .l {
      .item {
        color: #666666;
        font-size: 28px;
        padding: 0 24px;
        min-height: 96px;
        line-height: 96px;
        position: relative;

        .bg {
          position: absolute;
          left: 50%;
          bottom: 18px;
          width: 36px;
          height: 6px;
          border-radius: 4px;
          margin-left: -18px;
        }
      }
    }

    .r {
      padding: 0 24px;
      font-size: 24px;
      font-weight: 500;
      color: #666;

      img {
        width: 32px;
        height: 32px;
        display: block;
      }
    }
  }
  .record-list {
    padding: 0 32px;
    .item {
      min-height: 140px;
      border-top: 2px solid #eeeeee;

      .show-detail-view {
        padding: 20px 0;
        .show-detail {
          background: #f7f7f7;
          border-radius: 8px;
          padding: 8px 20px 24px 20px;

          .detail-it {
            display: flex;
            align-items: center;
            margin-top: 16px;
            font-size: 20px;

            .name {
              flex: 1;
              color: #333;

              span {
                color: #999;
              }
            }

            .price {
              color: #000;
              font-size: 24px;
              font-family: NUM;
            }
          }
        }
      }

      .lt {
        font-size: 24px;
      }

      .l,
      .r {
        padding-top: 32px;
      }

      .lb {
        margin-top: 8px;
        font-size: 20px;
        color: #999;

        &.has-img {
          color: #347ff4;
        }

        &.frozen {
          color: #fb7575;
        }

        div {
          max-width: 430px;
        }

        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }

      .r {
        font-family: NUM;
        font-size: 36px;
        text-align: right;
        .hong {
          color: #fb7575;
        }
        .hei {
          color: #000000;
        }
        .rb {
          font-size: 20px;
          margin-top: 6px;
          color: #999;

          &.err {
            color: #fb7575;
          }
          &.ing {
            color: #fb7575;
          }
          &.finish {
            color: #00c8a0;
          }
          &.detail {
            color: #347ff4;
            margin-left: 10px;
          }
          &.frozen {
            color: #fb7575;
          }
        }
      }

      &:first-child {
        border-top: none;
      }
    }
  }
}
</style>